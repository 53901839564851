<template>
  <TosListVuetify serviceName="block" entityName="block-rules" :headers="headers"  ref="form">
    <template #customFilters="slotProps">
      <v-container fluid>
        <v-row>
          <v-col cols="2">
            <v-text-field :label="tLabel('nome')" v-model="slotProps.data.nameLike" />
          </v-col>
          <v-col cols="2">
            <v-text-field :label="tLabel('Descrizione')" v-model="slotProps.data.descriptionLike" />
          </v-col>
          <v-col cols="2">
            <v-select :label="tLabel('Direzione')" v-model="slotProps.data.direction" :items="directions" :enumsMap="directionsMap" />
          </v-col>
          <v-col cols="2">
            <v-select :label="tLabel('Categoria')" v-model="slotProps.data.category" :items="category" :enumsMap="categoryMap" />
          </v-col>
          <v-col cols="2">
            <v-select :label="tLabel('Sottocategoria')" v-model="slotProps.data.subCategory" :items="subCategory" :enumsMap="subCategoryMap" />
          </v-col>
        </v-row>
      </v-container>
    </template>

    <template #editF="slotProps">
      <div v-if="slotProps.data" class="p-cardialog-content">
        <v-row>
          <v-col cols="2">
            <v-text-field :label="tLabelRequired('Nome')" v-model="slotProps.data.name" :rules="[rules.required]" />
          </v-col>
          <v-col cols="2">
            <v-text-field :label="tLabel('Descrizione')" v-model="slotProps.data.description" />
          </v-col>
          <v-col cols="2">
            <v-select :label="tLabel('Direzione')" v-model="slotProps.data.direction" :items="directions" :enumsMap="directionsMap" />
          </v-col>
           <v-col cols="2">
            <v-text-field :label="tLabel('Messaggio di Errore')" v-model="slotProps.data.messageError" />
          </v-col>
          <v-col cols="2">
            <v-select :label="tLabelRequired('Categoria')" v-model="slotProps.data.category" :items="category" :enumsMap="categoryMap" :rules="[rules.required]"/>
          </v-col>
          <v-col cols="2">
            <v-select :label="tLabel('Sottocategoria')" v-model="slotProps.data.subCategory" :items="subCategory" :enumsMap="subCategoryMap" />
          </v-col>
<!--      
          <v-col cols="2">
            <v-checkbox :label="tLabel('Manuale')" v-model="slotProps.data.manual" />
          </v-col>
        -->          
          
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-title>
                {{ tLabel("Condizioni") }}
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols='9'>
                    <BlockCondition :parentCondition="condition" :operationType="operationType" :fieldList="fieldList" :operationTypeMap="operationTypeMap"
                    
                    @setParent="(value) => condition = value"/>
                  </v-col>

                  <v-col cols="3" class="text-right">
                    <v-btn color="primary" @click="addCondition">
                      <v-icon>mdi-plus</v-icon>
                      {{ tLabel("Aggiungi") }}
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead class="header">
                          <tr>
                            <th />
                            <th class="text-left">{{ tLabel("Nome") }}</th>
                            <th class="text-left">{{ tLabel("Tipo Operazione") }}</th>
                            <th class="text-left">{{ tLabel("Valore") }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="c in slotProps.data.conditions" :key="c.fieldName + c.value + c.operationType">
                            <td>
                              <v-btn icon color="primary" @click="removeCondition(c)">
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                            </td>
                            <td>{{ c.fieldName }}</td>
                            <td>{{ c.operationType }}</td>
                            <td>{{ c.value }}</td>
                          </tr>
                     
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </template>
  </TosListVuetify>
</template>

<script>
import TosListVuetify from "../../components/anagrafiche/TosListVuetify";
import FormatsMixins from "../../mixins/FormatsMixins";
import ecustom from "@/configs/ecustom";
import gate from "@/configs/gate";
import yard from "@/configs/yard";
import BlockCondition from "./BlockCondition";
export default {
  name: "BlockRules",
  data() {
    return {
      condition: {},
      conditionList: [],
      operationType: [],
      operationTypeMap: {},
      directions: [],
      directionsMap: {},
      subCategory: [],
      subCategoryMap: {},
      category: [],
      categoryMap: {},

      headers: [
        { text: "", value: "action" },
        { text: "Nome", value: "name" },
        { text: "Direzione", value: "direction" },
        { text: "Descrizione", value: "description" },
        { text: "Categoria", value: "category" },
        { text: "Sottocategoria", value: "subCategory" },
        //{ text: "Manuale", value: "manual" },
        { text: "Messaggio di Errore", value: "messageError" },
      ],
    };
  },
  components: {
    TosListVuetify: TosListVuetify, BlockCondition
  },

  created() {},
  async mounted() {
    await this.loadCombo();
  },
  methods: {
    addCondition() {
      if(!this.condition || !this.condition.field || !this.condition.operationType) return;
      if (!this.$refs.form.item.conditions) this.$refs.form.item.conditions = [];
      this.condition = this.cleanUpRecursive(this.condition);
      this.$refs.form.item.conditions.push(this._.cloneDeep(this.condition));
      this.condition = {};
    },
    cleanUpRecursive(condition){
      let field = condition.field;
      delete condition.field;
      condition.fieldName = field.key;
      if(condition.blockCondition){
        condition.blockCondition = this.cleanUpRecursive(condition.blockCondition);
      }
      return condition;
    },

    removeCondition(c) {
      this.$refs.form.item.conditions = this.$refs.form.item.conditions.filter((item) => item !== c);
    },

    async loadCombo() {
      const all = await this.$api.yardData.getLookupsBlocks();
      this.operationType = this.mapList(this.operationTypeMap, all.operationType);
      this.directions = this.mapList(this.directionsMap, all.directions);
      this.subCategory = this.mapList(this.subCategoryMap, all.subCategory);
      this.category = this.mapList(this.categoryMap, all.category);
    },

    mapList(map, list) {
      list.forEach((element) => {
        map[element.value] = element.text;
      });
      return list;
    },
  },
  mixins: [FormatsMixins],
  computed: { 
    fieldList(){
      if(!this.$refs?.form?.item?.category) return [];
      switch(this.$refs.form.item.category){
        case 'YARD': return yard;
        case 'ECUSTOM': return ecustom;
        case 'GATE': return gate;
        default: return [];
      }
    },

  }
};
</script>
