<template>
<v-container>
  <v-row>
    <v-col cols="4">
      <!-- TODO: fix tLabel inside the list -->
      <v-select :label="tLabel('Nome')" v-model="condition.field" :item-text="(item) => (item.title ? item.title : item.key)" :item-value="(item) => item" :items="fieldList" />
    </v-col>
    <v-col cols="4">
      <v-select :label="tLabel('Tipo operazione')" v-model="condition.operationType" :items="operationType" :enumsMap="operationTypeMap" />
    </v-col>

    <v-col cols="4" v-if="condition && condition.field">
      <v-text-field
        v-if="condition.field.type === 'String'"
        :label="tLabel('Valore')"
        v-model="condition.value"
        :maxlength="condition.field.length ? condition.field.length : 255"
        counter
      />

      <v-select :label="tLabel('Valore')" v-else-if="condition.field.type === 'Enum'" v-model="condition.value" :items="condition.field.enums" />
      <v-text-field
        v-if="condition.field.type === 'Number'"
        :step="condition.field.step ? condition.field.step : 1"
        :min="condition.field.min"
        :label="tLabel('Valore')"
        v-model="condition.value"
        type="number"
      />
      <v-datetime-picker v-else-if="condition.field.type === 'Date'" :label="tlabel('Valore')" v-model="condition.value" />
    </v-col>
  </v-row>
  <BlockCondition v-if="condition.field && condition.field.type == 'List'" :parentCondition="recursiveCondition" 
   :operationType="operationType" :fieldList="condition.field.list" :operationTypeMap="operationTypeMap"
   @setRecursive="(value) => recursiveCondition = value"/>
</v-container>
</template>


<script> 
export default {
    name: 'BlockCondition',
    props: {
        parentCondition: {
            type: Object,
            required: true
        },
        operationType: {
            type: Array,
            required: true
        },
        fieldList: {
            type: Array,
            required: true
        },
        operationTypeMap: {
            type: Object,
            required: true
        }
    },
    data(){
        return {
            condition: this.parentCondition,
            recursiveCondition: {}
        }
    },
    watch: {
        condition: {
            handler: function (val, oldVal) {
                console.log('condition changed', val, oldVal)
                this.$emit('setParent', val);
            },
            deep: true
        },
        recursiveCondition: {
            handler: function (val, oldVal) {
               console.log('setRecursive changed', val, oldVal)
               this.condition.blockCondition = val;
               this.$emit('setRecursive', val);

            },
            deep: true
        },
        parentCondition: {
            handler: function (val, oldVal) {
                console.log('parentCondition changed', val, oldVal)
                this.condition = val;
            },
            deep: true
        }

    },
}

</script>
