export default [
  {
    key: "code",
    title: "Code",
    type: "String",
    length: 100,
  },
  {
    key: "objectCode",
    title: "Object Code",
    type: "String",
    length: 50,
  },
  {
    key: "sysType",
    title: "System Type",
    type: "Enum",
    enums: [
      { text: "UNIT", value: "UNT" },
      { text: "GOODS", value: "GOO" },
      { text: "RORO", value: "RRO" },
      { text: "PERSON", value: "PER" },
      { text: "WAGON", value: "WAG" },
      { text: "EQUIPMENT", value: "EQU" },
      { text: "VESSEL", value: "VES" },
    ],
    enumType: "SysTypeEnum",
  },
  {
    key: "role",
    title: "Role",
    type: "Enum",
    enums: [
      { text: "UNIT", value: "UNT" },
      { text: "CARRIER", value: "CRR" },
      { text: "DRIVER", value: "DRV" },
      { text: "EQU", value: "EQUIPMENT" },
      { text: "TEMPORARY", value: "TMP" },
    ],
    enumType: "VisitRoleEnum",
  },
  {
    key: "custType",
    title: "Customer Type",
    type: "String",
    length: 3,
  },
  {
    key: "altCode",
    title: "Alternative Code",
    type: "String",
    length: 50,
  },
  {
    key: "qty",
    title: "Quantity",
    type: "Number",
  },
  {
    key: "grossweight",
    title: "Gross Weight",
    type: "Number",
    step: 0.001,
  },
  {
    key: "goods",
    title: "Goods",
    type: "String",
  },
  {
    key: "extRef",
    title: "External Reference",
    type: "String",
  },
  {
    key: "groupCode",
    title: "Group Code",
    type: "String",
    length: 20,
  },
  {
    key: "lot",
    title: "Lot",
    type: "String",
    length: 20,
  },
  {
    key: "description",
    title: "Description",
    type: "String",
    length: 255,
  },
  {
    key: "remarks",
    title: "Remarks",
    type: "String",
    length: 255,
  },
  {
    key: "primaryFlg",
    title: "Primary Flag",
    type: "String",
    length: 1,
  },
  {
    key: "positionType",
    title: "Position Type",
    type: "Enum",
    enums: [
      { text: "VISIT", value: "V" },
      { text: "YARD_AREA", value: "Y" },
      { text: "EXTERNAL_AREA", value: "E" },
      { text: "EXTERNAL_RAIL", value: "R" },
      { text: "EXTERNAL_VESSEL", value: "S" },
    ],
    enumType: "VisitPositionTypeEnum",
  },

  {
    key: "positionExtId",
    title: "Position Ext Id",
    type: "String",
  },
  {
    key: "positionCode",
    title: "Position Code",
    type: "String",
  },
  {
    key: "positionStatus",
    title: "Position Status",
    type: "Enum",
    enums: [
      { text: "ADVISED", value: "A" },
      { text: "INSIDE", value: "I" },
      { text: "DEPARTED", value: "D" },
    ],
    enumType: "PositionStatusEnum",
  },
  {
    key: "status",
    title: "Status",
    type: "Enum",
    enums: [
      { text: "ADVISED", value: "A" },
      { text: "ENTERING", value: "E" },
      { text: "YARD", value: "Y" },
      { text: "LEAVING", value: "L" },
      { text: "DEPARTED", value: "D" },
      { text: "CLOSED", value: "C" },
    ],
    enumType: "VisitStatusEnum",
  },
  {
    key: "customsStatus",
    title: "Customs Status",
    type: "Enum",
    enumType: "CustomsStatusEnum",
    enums: [
      { text: "DOMESTIC", value: "D" },
      { text: "FOREIGN", value: "F" },
    ],
  },

  {
    key: "countType",
    title: "Count Type",
    type: "Enum",
    enumType: "CountTypeEnum",
    enums: [
      { text: "ITEMS", value: "I" },
      { text: "LASTMOVE", value: "L" },
    ],
  },
  {
    key: "qtyInitial",
    title: "Quantity Initial",
    type: "Integer",
  },
  {
    key: "dataIn",
    title: "Data In",
    type: "Date",
  },
  {
    key: "dataOut",
    title: "Data Out",
    type: "Date",
  },


  {
    key: "handlingGroupCode",
    title: "Handling Group Code",
    type: "String",
  },
  {
    key: "handlingCarrierCode",
    title: "Handling Carrier Code",
    type: "String",
  },
  {
    key: "handlingVoyageCode",
    title: "Handling Voyage Code",
    type: "String",
    length: 50,
  },
  {
    key: "handlingDirection",
    title: "Handling Direction",
    type: "Enum",
    enumType: "DirectionEnum",
    enums: [
      { text: "IN", value: "IN" },
      { text: "OUT", value: "OUT" },
      { text: "TRANSFORM", value: "TRF" },
    ],
  },
  {
    key: "handlingTransportType",
    title: "Handling Transport Type",
    type: "Enum",
    enumType: "TransportTypeEnum",
    enums: [
      { text: "TRUCK", value: "TRU" },
      { text: "TRAIN", value: "TRA" },
      { text: "VESSEL", value: "VES" },
      { text: "MISC", value: "MSC" },
      { text: "INTERNAL", value: "INT" },
    ],
  },

];
